import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {EaiChatModule} from "@corvus-it-poland/eai-chat";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MarkdownModule} from "ngx-markdown";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    EaiChatModule.forRoot({
      enabled: true,
      apiUrl: 'https://api.quick-ai.corvus-it.com',
      socketUrl: 'wss://api.quick-ai.corvus-it.com',
      localStoragePrefix: 'eai-chat',
      env: '',
      iFrameMode: true,
    }),
    MarkdownModule.forRoot()
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
